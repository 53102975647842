<script>
import { vehicleTypeService } from "../../../helpers/backend/vehicle_type.service";
// import Datepicker from "vuejs-datepicker";
export default {
  components: {
    // Datepicker,
  },
  data() {
    return {
      form: {
        id: null,
        vehicle_brand: "",
        vehicle_series: "",
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      vehicle_brand_options: [],
    };
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.busy = true;
      vehicleTypeService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to Insert/Update Vehicle type. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.vehicle_brand = "";
      this.form.vehicle_series = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
      this.fetchData();
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    initUpdate(id) {
      this.reset();
      this.$refs.vehicle_brand.$el.focus();
      this.busy = true;
      vehicleTypeService.getById(id).then(
        (data) => {
          if (data.vehicle_type != null) {
            this.form.id = data.vehicle_type.ID;
            this.form.vehicle_brand = data.vehicle_type.vehicle_brand;
            this.form.vehicle_series = data.vehicle_type.vehicle_series;
          } else {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get vehicle to update. Error : Vehicle not found";
          }
          this.busy = false;
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get worker type to update. Error : " + err;
          this.busy = false;
        }
      );
    },
    fetchData() {
      vehicleTypeService.getAll().then(
        (data) => {
          this.vehicle_brand_options = [];
          for (let index = 0; index < data.vehicle_types.length; index++) {
            const element = data.vehicle_types[index];
            if (!this.vehicle_brand_options.includes(element.vehicle_brand)){
              this.vehicle_brand_options.push(element.vehicle_brand)
            }
          }
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get vehicle type. Error : " + err;
        }
      );
    },
  },
};
</script>


<template>
  <div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Vehicle Name:"
                label-for="input-1-vehicle-brand"
              >
                <b-form-input
                  list="input-list"
                  id="input-1-vehicle-brand"
                  v-model="form.vehicle_brand"
                  placeholder="Vehicle Brand"
                  required
                  autocomplete="off"
                  ref="vehicle_brand"
                ></b-form-input>
                <b-form-datalist
                  id="input-list"
                  :options="vehicle_brand_options"
                ></b-form-datalist>
              </b-form-group><b-form-group
                id="input-group-2"
                label="Vehicle Series:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  type="text"
                  autocomplete="off"
                  v-model="form.vehicle_series"
                  placeholder="Vehicle Series"
                  required
                ></b-form-input>
              </b-form-group>
              <!-- <b-form-group
                id="input-group-3"
                label="Vehicle Year:"
                label-for="input-3"
              >
                <Datepicker
                  v-model="form.vehicle_year"
                  format="yyyy"
                  minimum-view="year"
                  name="datepicker"
                  id="input-3"
                  input-class="input-class"
                ></Datepicker>
              </b-form-group> -->

              <b-button type="submit" variant="primary">{{
                isUpdateState ? "Update" : "Submit"
              }}</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>



