<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import List from './list'
import Form from './form'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    Form,
  },
  data() {
    return {
      title: "Vehicle Type",
      items: [
        {
          text: "Agenda"
        },
        {
          text: "Vehicle Type",
          active: true
        }
      ]
    };
  },
  methods :{
    onRefresh(){
      this.$refs.list.fetchData()
    },
    onEdit(id){
      this.$refs.form.initUpdate(id)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Form ref="form" @refresh="onRefresh" />
        <List ref="list" @onEdit="onEdit"/>
      </div>
      
    </div>
  </Layout>
</template>